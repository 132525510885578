import React, { useState, useEffect, useCallback } from 'react';
import { View, Text, TouchableOpacity } from 'react-native-web';
import { Dialog } from './Dialog'; // Assuming Dialog is already correct and functional
import { isEmpty } from '../ui/utils';
import { useApi } from '../hooks';
import { LayoutBox } from './Layout';

const CircularProgress = ({ progress, onExpand }) => {
  const styles = {
    container: {
      width: '50px',
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      zIndex: 1000,
    },
    svg: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    circleBackground: {
      fill: 'none',
      stroke: '#e0e0e0',
      strokeWidth: '10',
    },
    circleProgress: {
      fill: 'none',
      stroke: '#28a745',
      strokeWidth: '10',
      strokeLinecap: 'round',
      strokeDasharray: '251.2',
      strokeDashoffset: `${251.2 - (251.2 * progress) / 100}`,
      transition: 'stroke-dashoffset 0.5s ease',
    },
    shadow: {
      filter: 'url(#shadow)',
    },
    progressText: {
      position: 'absolute',
      fontSize: '12px',
      fontWeight: 'bold',
      color: '#28a745',
    },
  };

  return (
    <TouchableOpacity onPress={onExpand}>
      <View style={styles.container}>
        <svg style={styles.svg} viewBox="0 0 100 100">
          <defs>
            <filter id="shadow" x="-5%" y="-20%" width="140%" height="140%">
              <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
              <feOffset dx="0" dy="2" result="offsetblur" />
              <feFlood floodColor="rgba(0, 0, 0, 0.3)" />
              <feComposite in2="offsetblur" operator="in" />
              <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <circle cx="50" cy="50" r="40" style={styles.circleBackground} />
          <circle cx="50" cy="50" r="40" style={{ ...styles.circleProgress, ...styles.shadow }} />
        </svg>
        <Text style={styles.progressText}>{progress}%</Text>
      </View>
    </TouchableOpacity>
  );
};

const MacavityTracking = ({ quoteId, isAdjustmentSuccessFul, adjustmentResponse, tab, quoteData, updateMacavity }) => {
  const [progress, setProgress] = useState(0);
  const [statusText, setStatusText] = useState('');
  const [startedAt, setStartedAt] = useState();
  const [showModal, setShowModal] = useState(false); // Start with false
  const [firstResponseReceived, setFirstResponseReceived] = useState(false);
  const [shouldMinimized, setShouldMinimized] = useState(false);
  const { getMacavityStatus } = useApi();

  const formatTime = (date) => {
    return date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const handleStatusUpdate = useCallback(
    ({ taskStatus, systemTime }) => {
      const formattedTime = formatTime(new Date(systemTime));

      if (taskStatus === 'PENDING' && !startedAt) {
        setStartedAt(`Started - ${formattedTime}`);
      }

      if (taskStatus === 'RUNNING') {
        setStatusText(`Running – ${formattedTime}`);
      }
    },
    [startedAt]
  );

  const handleStop = useCallback(
    (response) => {
      if (response.taskStatus === 'STOPPED') {
        setProgress(100);
        setStatusText(`Complete – ${formatTime(new Date(response.systemTime))}`);
        setTimeout(() => {
          setShouldMinimized(false);
          setShowModal(false);
          updateMacavity();
        }, 3000);
      } else {
        setShowModal(false);
        setShouldMinimized(false);
      }
    },
    [updateMacavity]
  );

  const updateProgress = useCallback((response) => {
    const status = response.taskStatus;
    const systemTime = response.systemTime;

    setProgress((prevProgress) => {
      let newProgress = prevProgress;

      if (newProgress < 50 && status === 'RUNNING') {
        newProgress = 50;
      } else if (newProgress < 80 && status === 'RUNNING') {
        newProgress = Math.min(newProgress + 2, 80);
      } else if (status === 'PENDING') {
        newProgress = Math.min(newProgress + 2, 50);
      } else {
        newProgress = Math.min(newProgress + 2, 80);
      }

      setStatusText(status !== 'PENDING' && `Running – ${formatTime(new Date(systemTime))}`);
      return newProgress;
    });
  }, []);

  const pollJobStatus = useCallback(
    (quoteId, ecsArnId) => {
      const intervalId = setInterval(async () => {
        try {
          const response = await getMacavityStatus(quoteId, ecsArnId);
          if (!firstResponseReceived && !isEmpty(response)) {
            setProgress(20);
            setFirstResponseReceived(true);
            setShouldMinimized(true);
          }
          if (isEmpty(response) || response.taskStatus === 'STOPPED') {
            clearInterval(intervalId);
            handleStop(response);
            return;
          }
          handleStatusUpdate(response);
          if (!ecsArnId && response.ecsArnId) {
            ecsArnId = response.ecsArnId;
          }
          updateProgress(response);
        } catch (error) {
          clearInterval(intervalId);
        }
      }, 5000);

      return intervalId;
    },
    [getMacavityStatus, handleStatusUpdate, handleStop, updateProgress, firstResponseReceived]
  );
  const initializeProgress = useCallback(
    (ecsArnId = null) => {
      return pollJobStatus(quoteId, ecsArnId);
    },
    [pollJobStatus, quoteId]
  );

  useEffect(() => {
    let ecsArnId = adjustmentResponse?.ecsArnId || null;
    const intervalId = initializeProgress(ecsArnId);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [initializeProgress, adjustmentResponse, isAdjustmentSuccessFul, quoteId]);

  const handleExpand = () => setShowModal(true);
  const handleClose = () => {
    setShowModal(false);
    setShouldMinimized(true);
  };
  return (
    <>
      {tab === 'CAT_MODELING' &&
        (showModal ? (
          <Dialog open={true} heading={'Macavity Tracking'} onClose={handleClose} hideExitButton={true}>
            <View style={{ width: '100%', paddingTop: '3px' }}>
              <View
                style={{
                  height: 15,
                  backgroundColor: '#e0e0e0',
                  borderRadius: 10,
                  overflow: 'hidden',
                  width: '100%',
                  marginBottom: 10,
                }}
              >
                <View
                  style={{
                    height: '100%',
                    backgroundColor: '#28a745',
                    width: `${progress}%`,
                    transition: 'width 0.4s ease',
                    animation: progress === 100 ? 'none' : 'pulse-fade 1.5s ease-in-out infinite',
                    boxShadow: '0 0 10px rgba(40, 167, 69, 0.5)',
                  }}
                />
              </View>
              <LayoutBox row justifyContent="space-between">
                {startedAt ? <Text>{startedAt}</Text> : <Text>{`Started - ${formatTime(new Date(quoteData?.lastChangeAt))}`}</Text>}
                {statusText && <Text>{statusText}</Text>}
              </LayoutBox>
            </View>
          </Dialog>
        ) : (
          shouldMinimized && <CircularProgress progress={progress} onExpand={handleExpand} />
        ))}
    </>
  );
};

export default MacavityTracking;
