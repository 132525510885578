function getNestedValue(data, field) {
  if (!data || !field) return undefined;

  return field.split('.').reduce((acc, part) => {
    if (acc && part in acc) {
      return acc[part];
    }
    return undefined;
  }, data);
}
export const exportToCsv = (gridReference) => {
  const rows = [];
  const headers = [];

  const columnDefs = gridReference.api.getAllGridColumns();

  columnDefs.forEach((col) => {
    headers.push(col.getColDef().headerName || col.getId());
  });

  // Get row data
  gridReference.api.forEachNode((node) => {
    const rowData = {};
    columnDefs.forEach((col) => {
      const colDef = col.getColDef();
      let value;
      if (colDef.valueGetter) {
        value = colDef.valueGetter({ data: node.data, colDef });
      } else if (colDef.field) {
        value = getNestedValue(node.data, colDef.field);
      }
      if (colDef.exportValueFormatter) {
        value = colDef.exportValueFormatter({
          value: value,
          data: node.data,
          node: node,
          colDef: colDef,
          column: col,
          api: gridReference.api,
          columnApi: gridReference.columnApi,
          context: gridReference.context,
        });
      } else if (colDef.valueFormatter) {
        value = colDef.valueFormatter({
          value: value,
          data: node.data,
          node: node,
          colDef: colDef,
          column: col,
          api: gridReference.api,
          columnApi: gridReference.columnApi,
          context: gridReference.context,
        });
      }

      if (colDef.dataType === 'number' && typeof value === 'string') {
        if (value.includes('%')) {
          const cleanedValue = value.replace('%', '').trim(); // Remove the percentage symbol
          const parsedValue = parseFloat(cleanedValue);
          value = isNaN(parsedValue) ? 'N/A' : (parsedValue / 100).toFixed(2); // Convert to decimal
        } else {
          const cleanedValue = value.replace(/[^0-9.-]/g, '');
          const parsedValue = parseFloat(cleanedValue);
          value = isNaN(parsedValue) ? 'N/A' : parsedValue;
        }
      } else {
        if (typeof value === 'string' && value.includes('$')) {
          const cleanedValue = value.replace(/[$,]/g, '');
          if (/[\d]+/.test(cleanedValue)) {
            const parsedValue = parseFloat(cleanedValue);
            value = isNaN(parsedValue) ? 'N/A' : parsedValue;
          } else {
            value = 'N/A';
          }
        }
      }

      rowData[col.getColDef().headerName || col.getId()] = value !== null && value !== undefined ? value : '';
    });

    rows.push(rowData);
  });

  return rows;
};
