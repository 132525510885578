import { useCallback, useState } from 'react';
import { useApi, useAsync } from '../hooks';
import { Box, Button, GridModal, Text } from '../ui';
import { useQuoteData } from '../pages/QuotePage/context/QuoteDataContext';

const ResetQuoteModal = ({ quoteId }) => {
  const { resetQuoteDetails } = useApi();
  const { update } = useQuoteData();

  const [resetModalOpen, setResetModalOpen] = useState(false);

  const handleResetQuote = useCallback(async () => {
    const response = await resetQuoteDetails(quoteId);
    if (response) setResetModalOpen(false);
    update();
  }, [resetQuoteDetails, quoteId, update]);

  const { execute: executeReset, status: resetStatus } = useAsync(handleResetQuote, { immediate: false });

  return (
    <>
      <Box display="flex" alignItems="start" justifyContent="center" flexWrap="wrap" backgroundColor="white" height={70}>
        <Box border="1px solid #919EAB52" borderRadius={8} marginRight="$2">
          <Button
            variant="text"
            color="$error"
            label="Reset"
            paddingX={16}
            onPress={() => {
              setResetModalOpen(true);
            }}
          />
        </Box>
        <GridModal
          open={resetModalOpen}
          title="Are You Sure You Want to Reset?"
          onClose={() => {
            setResetModalOpen(false);
          }}
          buttonText="Confirm"
          handlePress={() => executeReset(quoteId)}
          loading={resetStatus === 'pending'}
        >
          <Text align="left" size="large" style={{ fontWeight: 500, padding: 16 }}>
            This will reset the quote reinsurance, fee, and requirement information to defaults. Are you sure?
          </Text>
        </GridModal>
      </Box>
    </>
  );
};

export default ResetQuoteModal;
