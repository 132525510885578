import React, { useCallback, useEffect, useState } from 'react';
import { Grid } from '../../../../ui/components/Grid';
import { currencyFormatter } from './utils';
import moment from 'moment-timezone';
import { exportToCsv } from '../../../../utils';
import { Box, Text } from '../../../../ui';

export const CATModeling = React.forwardRef((props, ref) => {
  const { macavity, setGridReferenceForTab, gridReference, setExportHandler, quote } = props;

  const [gridsData, setGridsData] = useState([]);

  const createColumnDefs = useCallback(
    (macavity) => {
      const baseCols = [
        {
          headerName: 'Return Period',
          field: 'returnPeriod',
          pinned: true,
          width: 150,
          flex: 1,
          filter: 'agTextColumnFilter',
          lockPinned: true,
          valueFormatter: (params) => {
            if (typeof params.value === 'number') {
              return params.value.toLocaleString();
            }
            return params.value;
          },
        },
        {
          headerName: 'Policy',
          field: 'policy',
          pinned: true,
          width: 150,
          flex: 1,
          filter: 'agTextColumnFilter',
          valueFormatter: (field) => (typeof field.value === 'number' ? currencyFormatter({ field }) : field.value),
        },
      ];

      const locationCols = macavity.quoteLocations.map((location, index) => {
        const matchingLocation = quote.locations.find((quoteLoc) => quoteLoc.id === location.locationId);
        return {
          headerName: `Location ${matchingLocation?.riskId || 'X'}`,
          field: `location_${index + 1}`,
          width: 150,
          flex: 1,
          filter: 'agTextColumnFilter',
          valueFormatter: (field) => {
            return typeof field.value === 'number' && field.data.policy !== 'Vehicle Count' ? currencyFormatter({ field }) : field.value;
          },
        };
      });

      return [
        {
          headerName: macavity.config === 'cap_5m' ? '$5MM Quota Share - Insured SCS Model' : 'Full TIV - Insured SCS Model',
          children: [...baseCols, ...locationCols],
        },
      ];
    },
    [quote]
  );

  const transformData = useCallback(
    (macavity) => {
      const rows = [];
      const returnPeriods = macavity.returnPeriods;

      returnPeriods.forEach((rp) => {
        const row = {
          returnPeriod: rp.returnPeriod,
          policy: rp.totalClaims,
        };

        macavity.quoteLocations.forEach((loc, index) => {
          const locReturnPeriod = loc.returnPeriods.find((locRp) => locRp.returnPeriod === rp.returnPeriod);
          row[`location_${index + 1}`] = locReturnPeriod ? locReturnPeriod.totalClaims : 0;
        });

        rows.push(row);
      });

      // Add AAL and Median AL rows
      const aalRow = { returnPeriod: 'AAL', policy: macavity.revisedAnnualMean };
      const medianALRow = { returnPeriod: 'Median AL', policy: macavity.annualMedian };

      macavity.quoteLocations.forEach((loc, index) => {
        aalRow[`location_${index + 1}`] = loc.annualMean || 0;
        medianALRow[`location_${index + 1}`] = loc.annualMedian || 0;
      });

      rows.push(aalRow);
      rows.push(medianALRow);

      const configRow = { policy: 'Config' };
      const nameRow = { policy: 'Name' };
      const addressRow = { policy: 'Address' };
      const tivRow = { policy: 'TIV' };
      const vehicleCount = { policy: 'Vehicle Count' };
      const deductibleRow = { policy: 'Deductible' };

      macavity.quoteLocations.forEach((loc, index) => {
        const matchingLocation = quote.locations.find((quoteLoc) => quoteLoc.id === loc.locationId);
        nameRow[`location_${index + 1}`] = matchingLocation ? matchingLocation.name : '';
        addressRow[`location_${index + 1}`] = matchingLocation ? matchingLocation.address : '';
        configRow[`location_${index + 1}`] = `Location ${matchingLocation?.riskId || 'X'}`;
        tivRow[`location_${index + 1}`] = loc.maxValue || 0;
        vehicleCount[`location_${index + 1}`] = loc.maxCount || 0;
        deductibleRow[`location_${index + 1}`] = loc.deductible || 0;
      });

      rows.push(configRow);
      rows.push(nameRow);
      rows.push(addressRow);
      rows.push(tivRow);
      rows.push(vehicleCount);
      rows.push(deductibleRow);

      return rows;
    },
    [quote]
  );

  useEffect(() => {
    if (macavity.length) {
      const data = macavity.map((macavityItem, index) => {
        macavityItem.quoteLocations?.sort((locA, locB) => {
          const locationA = quote.locations.find((quoteLoc) => quoteLoc.id === locA.locationId);
          const locationB = quote.locations.find((quoteLoc) => quoteLoc.id === locB.locationId);

          // Extract riskId for comparison; default to a large value if riskId is not found
          const riskIdA = locationA?.riskId || Number.MAX_SAFE_INTEGER;
          const riskIdB = locationB?.riskId || Number.MAX_SAFE_INTEGER;

          return riskIdA - riskIdB;
        });
        return {
          tabName: 'CAT_MODELING',
          columnDefs: createColumnDefs(macavityItem),
          rowData: transformData(macavityItem),
          timestamp: macavityItem?.runTimestamp,
          tableName: `table${index}`,
        };
      });
      setGridsData(data);
    }
  }, [macavity, createColumnDefs, quote, transformData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const grids = document.querySelectorAll('.ag-paging-panel.ag-unselectable:not(.ag-hidden)');

      grids.forEach((paginationDiv, index) => {
        const timestampData = gridsData[index]?.timestamp;
        const formattedTimestamp = timestampData ? moment(timestampData).local().format('MMMM D, YYYY HH:mm:ss [CDT]') : 'N/A';
        let timestampDiv = paginationDiv.querySelector('.last-updated');

        if (timestampDiv) {
          timestampDiv.textContent = `Last Updated: ${formattedTimestamp}`;
        } else {
          timestampDiv = document.createElement('div');
          timestampDiv.className = 'last-updated';
          timestampDiv.textContent = `Last Updated: ${formattedTimestamp}`;
          timestampDiv.style.textAlign = 'left';
          paginationDiv.insertBefore(timestampDiv, paginationDiv.firstChild);
        }
      });
    }, 0);

    return () => clearTimeout(timer);
  }, [gridsData]);

  useEffect(() => {
    gridsData.forEach((gridData) => {
      const tableGridRef = gridReference[gridData.tableName];
      if (tableGridRef) {
        setExportHandler(() => exportToCsv(tableGridRef), gridData.tableName);
      }
    });
  }, [gridsData, gridReference]);

  return (
    <div className="ag-theme-quartz" style={{ maxHeight: '1500px' }}>
      {!!macavity.length ? (
        gridsData.map((gridData, index) => (
          <div key={index} style={{ marginBottom: '20px', maxHeight: '800px', overflow: 'scroll' }} className="ag-theme-quartz">
            <Grid
              data={gridData.rowData}
              columns={gridData.columnDefs}
              masterDetail={true}
              floatingFilter={false}
              pageSize={20}
              setGridReference={(ref) => setGridReferenceForTab({ tabName: gridData.tabName, tableName: gridData.tableName, ref })} // Retrieve the correct grid reference
              gridReference={gridReference[gridData.tabName]?.[gridData.tableName] || null}
            />
          </div>
        ))
      ) : (
        <Box justifyContent="center" margin="auto" padding="4px">
          <Text value="No Data Available"></Text>
        </Box>
      )}
    </div>
  );
});
