import React, { useEffect, useState } from 'react';
import { Box } from '../../../../ui';
import { ViewTabs } from '../../../../components/ViewTabs';
import { Coverages } from '../QuoteGrids/Coverages';
import { RiskConcentration } from '../QuoteGrids/RiskConcentration';
import { CATModeling } from '../QuoteGrids/CATModeling';
import { BindFormsView } from '../BindFormsView';
import { Fees } from '../QuoteGrids/Fees';
import { useAdjustmentState } from '../../context/QuoteAdjustment/AdjustmentContext';
import { exportAllGrids } from '../../../../utils/exportAllGrids';

export const QuotesViewTabs = {
  FEES: 'FEES',
  COVERAGES: 'COVERAGES',
  CAT_MODELING: 'CAT_MODELING',
  RISK_CONCENTRATION: 'RISK_CONCENTRATION',
  BIND_FORMS: 'BIND_FORMS',
};

const QuotesGridView = ({
  tab,
  data,
  productCoverages,
  concentrations,
  macavity,
  onSelectionChanged,
  update,
  updating,
  lastUpdated,
  modalOpen,
  setModalOpen,
  quoteData,
  setQuoteData,
  gridReferences,
  importModal,
  setImportModal,
  setActiveExportFunction,
  setGridReferenceForTab,
}) => {
  return (
    <div>
      <div style={{ display: tab === QuotesViewTabs.FEES ? 'block' : 'none' }}>
        <Fees
          quotes={quoteData}
          onSelectionChanged={onSelectionChanged}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setQuoteData={setQuoteData}
          gridReference={gridReferences?.FEES}
          importModal={importModal}
          setImportModal={setImportModal}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.FEES)}
          setGridReference={(ref) => setGridReferenceForTab({ tabName: 'FEES', ref })}
        />
      </div>
      <div style={{ display: tab === QuotesViewTabs.COVERAGES ? 'block' : 'none' }}>
        <Coverages
          quotes={quoteData}
          productCoverages={productCoverages}
          onSelectionChanged={onSelectionChanged}
          modalOpen={modalOpen}
          setQuoteData={setQuoteData}
          setModalOpen={setModalOpen}
          importModal={importModal}
          gridReference={gridReferences?.COVERAGES}
          setImportModal={setImportModal}
          concentrations={concentrations}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.COVERAGES)}
          setGridReference={(ref) => setGridReferenceForTab({ tabName: 'COVERAGES', ref })}
        />
      </div>
      <div style={{ display: tab === QuotesViewTabs.CAT_MODELING ? 'block' : 'none' }}>
        <CATModeling
          macavity={macavity}
          quote={data}
          gridReference={gridReferences?.CAT_MODELING}
          setExportHandler={(exportFn, tableName) => setActiveExportFunction(exportFn, QuotesViewTabs.CAT_MODELING, tableName)} // Set export handler for specific tables
          setGridReferenceForTab={setGridReferenceForTab}
        />
      </div>
      <div style={{ display: tab === QuotesViewTabs.RISK_CONCENTRATION ? 'block' : 'none' }}>
        <RiskConcentration
          quotes={data}
          concentrations={concentrations}
          gridReference={gridReferences?.RISK_CONCENTRATION}
          setExportHandler={(exportFn) => setActiveExportFunction(exportFn, QuotesViewTabs.RISK_CONCENTRATION)}
          setGridReference={(ref) => setGridReferenceForTab({ tabName: 'RISK_CONCENTRATION', ref })}
        />
      </div>
      <div style={{ display: tab === QuotesViewTabs.BIND_FORMS ? 'block' : 'none' }}>
        <BindFormsView data={data} update={update} updating={updating} lastUpdated={lastUpdated} />
      </div>
    </div>
  );
};

export const QuoteTabs = React.forwardRef((props, ref) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [gridParams, setGridParams] = useState({});
  const [selectedFields, setSelectedFields] = useState({});
  const [exportHandlers, setExportHandlers] = useState({});
  const { isAdjustmentSuccessFul, adjustmentResponse, setAdjustmentResponse } = useAdjustmentState();
  const quoteTabsKeys = Object.keys(QuotesViewTabs);
  const [quoteData, setQuoteData] = useState(props.data);
  const [importModal, setImportModal] = useState(false);
  const [gridReferences, setGridReferences] = useState({
    FEES: null,
    COVERAGES: null,
    CAT_MODELING: {
      table0: null,
      table1: null,
    },
    RISK_CONCENTRATION: null,
  });

  const setGridReferenceForTab = ({ tabName, tableName, ref }) => {
    if (tabName === 'CAT_MODELING') {
      setGridReferences((prevRefs) => ({
        ...prevRefs,
        CAT_MODELING: {
          ...prevRefs.CAT_MODELING,
          [tableName]: ref,
        },
      }));
    } else {
      setGridReferences((prevRefs) => ({
        ...prevRefs,
        [tabName]: ref,
      }));
    }
  };

  const setActiveExportFunction = (exportFn, gridType, tableName = null) => {
    setExportHandlers((prevHandlers) => {
      if (gridType === 'CAT_MODELING') {
        return {
          ...prevHandlers,
          CAT_MODELING: {
            ...(prevHandlers.CAT_MODELING || {}),
            [tableName]: exportFn,
          },
        };
      } else {
        return {
          ...prevHandlers,
          [gridType]: exportFn,
        };
      }
    });
  };

  const setGridReference = (params) => {
    setGridParams(params);
  };

  const onSelectionChanged = (context) => {
    if (context) {
      const fields = context.api.getSelectedRows();
      setSelectedFields(fields);
    }
  };
  const initialTab = QuotesViewTabs.FEES;
  const [viewTab, setViewTab] = useState(initialTab || QuotesViewTabs.FEES);
  useEffect(() => {
    setModalOpen(false);
  }, [viewTab]);

  const getLabel = (name, label) => {
    if (name === QuotesViewTabs.FEES) {
      return 'Fees';
    }
    if (name === QuotesViewTabs.COVERAGES) {
      return `Coverages`;
    }
    if (name === QuotesViewTabs.CAT_MODELING) {
      return `CAT Modeling`;
    }
    if (name === QuotesViewTabs.RISK_CONCENTRATION) {
      return `Risk Concentration`;
    }
    if (name === QuotesViewTabs.BIND_FORMS) return `Bind Forms`;
    return label;
  };

  useEffect(() => {
    setQuoteData(props.data);
  }, [props.data]);

  return (
    <>
      <Box
        backgroundColor={'white'}
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="#DFE3E8"
        padding={'0px 18px'}
        justifyContent="space-between"
        display="flex"
        paddingLeft={10}
      >
        <Box display="flex" flex={1} flexWrap="wrap" columnGap="24px" rowGap="8px">
          <ViewTabs
            getLabel={getLabel}
            tabs={quoteTabsKeys}
            onTabChanged={setViewTab}
            gridReference={gridParams}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            quoteData={quoteData}
            setQuoteData={setQuoteData}
            importModal={importModal}
            setImportModal={setImportModal}
            quotesView={true}
            onExport={() => exportAllGrids({ gridReferences, exportHandlers, workbookName: quoteData.insured })}
            isAdjustmentSuccessFul={isAdjustmentSuccessFul}
            adjustmentResponse={adjustmentResponse}
            setAdjustmentResponse={setAdjustmentResponse}
            {...props}
          />
        </Box>
      </Box>
      <QuotesGridView
        tab={viewTab}
        {...props}
        setGridReference={setGridReference}
        onSelectionChanged={onSelectionChanged}
        modalOpen={modalOpen}
        quoteData={quoteData}
        gridReferences={gridReferences}
        setQuoteData={setQuoteData}
        setModalOpen={setModalOpen}
        importModal={importModal}
        setImportModal={setImportModal}
        setActiveExportFunction={setActiveExportFunction}
        setGridReferenceForTab={setGridReferenceForTab}
      />
    </>
  );
});
