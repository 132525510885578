import * as XLSX from 'xlsx';
import { parseCSVToJSON } from './parseCSVToJSON';

export const exportAllGrids = ({ gridReferences, exportHandlers, workbookName }) => {
  const workbook = XLSX.utils.book_new();

  Object.keys(gridReferences).forEach((tabName) => {
    const gridRef = gridReferences[tabName];

    if (tabName === 'CAT_MODELING') {
      const catModelingData = [];
      const catModelingTables = gridReferences['CAT_MODELING'];
      catModelingData.push({
        header: '$5MM Quota Share - Insured SCS Model',
      });
      Object.keys(catModelingTables).forEach((tableName, index) => {
        const gridRef = catModelingTables[tableName];

        if (gridRef && gridRef.api) {
          const exportHandler = exportHandlers['CAT_MODELING'][tableName];

          if (exportHandler) {
            const customData = exportHandler();
            if (Array.isArray(customData) && customData.length > 0) {
              const columnNames = Object.keys(customData[0]);
              catModelingData.push(Object.fromEntries(columnNames.map((name) => [name, name])));
              catModelingData.push(...customData);

              // After the first table's data, push three empty rows
              if (index === 0) {
                catModelingData.push({}, {}, {});
                catModelingData.push({
                  header: 'Full TIV - Insured SCS Model',
                });
              }
            } else {
              console.error(`Parsed data for ${tableName} is not in an array format or is empty.`);
            }
          }
        }
      });

      if (catModelingData.length > 0) {
        const worksheet = XLSX.utils.json_to_sheet(catModelingData, { skipHeader: true }); // Set skipHeader to true
        XLSX.utils.book_append_sheet(workbook, worksheet, 'CAT_MODELING');
      }
    } else if (tabName === 'FEES') {
      if (gridRef && gridRef.api) {
        const exportHandler = exportHandlers[tabName];
        if (exportHandler) {
          const customData = exportHandler();

          if (typeof customData === 'string') {
            const sanitizedData = customData.replace(/"/g, '');
            const parsedData = parseCSVToJSON(sanitizedData);
            if (Array.isArray(parsedData)) {
              const worksheet = XLSX.utils.json_to_sheet(parsedData);
              XLSX.utils.book_append_sheet(workbook, worksheet, tabName);
            } else {
              console.error(`Parsed data for ${tabName} is not in an array format.`);
            }
          }
        }
      }
    } else {
      // For other grids
      if (gridRef && gridRef.api) {
        const exportHandler = exportHandlers[tabName];
        if (exportHandler) {
          const customData = exportHandler();
          if (Array.isArray(customData)) {
            const worksheet = XLSX.utils.json_to_sheet(customData);
            XLSX.utils.book_append_sheet(workbook, worksheet, tabName);
          } else {
            console.error(`Parsed data for ${tabName} is not in an array format.`);
          }
        }
      }
    }
  });
  XLSX.writeFile(workbook, `${workbookName} - export_20240919_154500.xlsx`);
};
