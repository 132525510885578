export const parseCSVToJSON = (csvString) => {
  const rows = csvString.split('\n');
  const headers = rows[0].split(',');

  const jsonData = rows
    .slice(1)
    .map((row) => {
      const values = row.split(',');
      return headers.reduce((acc, header, index) => {
        acc[header] = values[index] || '';
        return acc;
      }, {});
    })
    .filter((obj) => Object.keys(obj).length > 0); // Filter out empty rows

  return jsonData;
};
