import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useAsync } from '../../../../hooks';
import { useApi } from '../../../../hooks/useApi';
import { useAppState } from '../../../../hooks/useAppState';
import { ProvideAdjustmentForm } from './AdjustmentFormContext';
import { ProvideAdjustmentPreview } from './AdjustmentPreviewContext';
import getAdjustmentPayload from './getAdjustmentPayload';

const AdjustmentContext = createContext(null);

function useAdjustmentState() {
  return useContext(AdjustmentContext) || {};
}

function ProvideQuoteAdjustment(props) {
  const { quoteData, refreshQuoteData, lastUpdated, refreshing, children } = props;

  const [{ canAdjustQuote }] = useAppState();

  const [adjusting, setAdjusting] = useState(false);
  const [isAdjustmentSuccessFul, setIsAdjustmentSuccessFul] = useState(false);
  const [adjustmentResponse, setAdjustmentResponse] = useState({});

  const [canPreview, setCanPreview] = useState(false);
  const [canExecuteBind, setCanExecuteBind] = useState(false);
  const [currentTab, setCurrentTab] = useState('COVERAGES');
  const [showOverViewSection, setShowOverViewSection] = useState(false);

  useEffect(() => {
    if (!canAdjustQuote && adjusting) {
      setAdjusting(false);
    }
  }, [canAdjustQuote, adjusting]);

  const enable = useCallback(() => {
    if (canAdjustQuote) {
      setAdjusting(true);
    }
  }, [setAdjusting, canAdjustQuote]);

  // const enableBindExecution = useCallback(() => {
  //   if (canBind) {
  //     setCanExecuteBind(true);
  //   }
  // }, [setCanExecuteBind, canBind]);

  const disable = useCallback(() => {
    setAdjusting(false);
    setCanExecuteBind(false);
  }, [setAdjusting]);

  const { id: quoteId, requests } = quoteData;
  const requestId = Array.isArray(requests) && requests.length ? requests[requests.length - 1].requestId : null;
  const { submitAdjustmentOnQuoteId } = useApi();
  const submitAdjustmentRequest = useCallback(
    async (data) => {
      // todo: changing a field's input triggers preview. changing it back to original value does not. <- fix
      // todo: removing coverage from location works. add location does not and is missing coverageType
      if (!data) {
        console.log('ERROR submitting adjustemnt. No data', data);
        return null;
      }
      if (!data.quote) {
        console.log('ERROR submitting adjustment. Missing notes or quote data', data);
        return null;
      }
      const { notes, quote } = data;
      if (!notes || typeof notes !== 'string' || !notes.trim()) {
        throw 'Missing note';
      }
      const payload = getAdjustmentPayload({ requestId, notes, quote });
      console.log('submitting adjustment with payload', payload);

      const adjustment = await submitAdjustmentOnQuoteId(quoteId, payload);
      setAdjustmentResponse(adjustment);
      console.log('submitted! response: ', adjustment);
      return adjustment;
    },
    [quoteId, submitAdjustmentOnQuoteId, requestId]
  );

  const { status, execute, error, setStatus } = useAsync(submitAdjustmentRequest, { immediate: false, resetValueOnExecute: true });

  useEffect(() => {
    if (status === 'success') {
      refreshQuoteData();
      setIsAdjustmentSuccessFul(true);
      setStatus('idle');
      setAdjusting(false);
    }
  }, [status, refreshQuoteData, setStatus]);

  const submitting = status === 'pending' || status === 'success' || refreshing;
  const success = status === 'success';

  const context = useMemo(() => {
    return {
      adjusting,
      enable,
      canExecuteBind,
      disable,
      error,
      submitting,
      success,
      canPreview,
      setCanExecuteBind,
      setCanPreview,
      setAdjusting,
      currentTab,
      setCurrentTab,
      showOverViewSection,
      setShowOverViewSection,
      isAdjustmentSuccessFul,
      adjustmentResponse,
      setAdjustmentResponse,
    };
  }, [
    adjusting,
    enable,
    disable,
    error,
    submitting,
    success,
    canPreview,
    setCanPreview,
    canExecuteBind,
    currentTab,
    showOverViewSection,
    setShowOverViewSection,
    isAdjustmentSuccessFul,
    adjustmentResponse,
    setAdjustmentResponse,
  ]);

  const defaultData = useMemo(() => {
    return {
      notes: '',
      quote: quoteData,
    };
  }, [quoteData]);

  return (
    <AdjustmentContext.Provider value={context}>
      {adjusting && canAdjustQuote ? (
        <ProvideAdjustmentForm defaultData={defaultData} submit={execute} key={`${quoteId}-${lastUpdated}`}>
          <ProvideAdjustmentPreview quoteId={quoteId} submitting={submitting} defaultData={defaultData} key={`${quoteId}-${lastUpdated}-preview`}>
            {children}
          </ProvideAdjustmentPreview>
        </ProvideAdjustmentForm>
      ) : (
        children
      )}
    </AdjustmentContext.Provider>
  );
}

export { AdjustmentContext, useAdjustmentState, ProvideQuoteAdjustment };
