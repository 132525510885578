import { isNull } from '../../../../ui/utils';

function getAdjustmentPayload({ quote, requestId, notes = '' }) {
  const { adjustmentFactor = 0, locations: adjustedLocations, fees: adjustedFees, targetPremium } = quote;
  console.log('getAdjustmentPayload - quote', { adjustmentFactor, locations: [...adjustedLocations], fees: [...adjustedFees], targetPremium });
  // check
  for (const loc of adjustedLocations) {
    const { coveredValue, coverages = [] } = loc;
    const validCoveredValue = coveredValue && typeof coveredValue === 'number' && coveredValue > 0;
    for (const cov of coverages) {
      const { payoutLimit } = cov;
      if (validCoveredValue && payoutLimit > coveredValue) {
        throw 'Invalid payoutlimit';
      }
    }
  }
  delete quote.application;
  delete quote.limits;
  if (!targetPremium) delete quote.targetPremium;

  const payload = {
    ...(!isNull(requestId) ? { requestId } : null),
    notes: notes || '',
    quote,
  };
  return payload;
}

export default getAdjustmentPayload;
