import { Box, Button, Text } from '../ui';
import { Dialog } from './Dialog';

const AddressUpdateWarningModal = ({ open, handleClose, handleSubmit, handleNewLocation }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      disableBackdropPress={true}
      actions={
        <Box flexDirection="row" alignItems="center" gap={18}>
          <Button variant="text" color="$primary" onPress={handleNewLocation}>
            You're Right, New Location
          </Button>
          <Button onPress={handleSubmit} color={'$primary'}>
            Save Anyways
          </Button>
        </Box>
      }
    >
      <Text align="left" size="large" style={{ fontWeight: 500, padding: 16 }}>
        The visible address does not contain important similarities to the geocoded address. You should probably make a new location{' '}
      </Text>
    </Dialog>
  );
};

export default AddressUpdateWarningModal;
