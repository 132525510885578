export function asInteger(value, withDecimals = false) {
  if (typeof value === 'string') {
    return withDecimals ? parseFloat(value, 10) : parseInt(value, 10);
  }
  return value;
}

export function formatMoney(number, { prefix = '', suffix = '', withDecimals = false } = {}) {
  try {
    if (!number) return `${prefix}0${suffix}`;

    return `${prefix} ${asInteger(number, withDecimals)
      .toFixed(withDecimals ? 2 : 0)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${suffix}`;
  } catch (error) {
    return '0';
  }
}
